@if (image().url) {
  <div class="h-full w-full">
    <img
      #img
      [ngSrc]="image().url"
      fill
      [attr.fetchpriority]="fetchpriority()"
      (load)="loaded()"
      (error)="errored()"
      priority
      itemprop="image"
      class="mix-blend-multiply border-0 {{ objectFit() }} {{
        loading() ? 'animate-pulse' : ''
      }} max-w-full max-h-full"
      [title]="alt()"
      [alt]="alt()"
    />

    @if (loading()) {
      <div
        class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
      >
        <app-spinner class="text-2xl text-primary"></app-spinner>
      </div>
    }
  </div>
}
