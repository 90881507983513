export type RangePropertyType = 'skill' | 'water' | 'light';

export type PropertyType =
  | 'potsize'
  | 'height'
  | 'plant-family'
  | RangePropertyType;

export const PropertyGroupIds: Record<PropertyType, string> = {
  potsize: '6ee5cbd3530a46828ef8f59ed6f85ef5',
  height: 'ff6608f729e1406f97f31ae967ce1ca1',
  skill: '0542fbe448a045ac9a7f06ea024184ea',
  water: 'ecbd3805ad0541259e615dbe20deae9b',
  light: 'c10bd49ec9d648dc9d7aa15486b99836',
  'plant-family': 'cb358c42c1a542e78598cbdb50276ae3',
};

export const PropertyValues: Record<
  RangePropertyType,
  Record<string, number>
> = {
  skill: {
    Pflegeleicht: 1,
    Mittel: 2,
  },
  water: {
    Feucht: 3,
    'M\u00e4\u00dfig': 2,
    Wenig: 1,
  },
  light: {
    'Schatten N/O/W': 1,
    'Halbschatten O/W/SW ': 2,
    'Leichte Sonne S/SW/SO': 2.5,
  },
};
