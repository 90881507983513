import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Schemas } from '../../../api-types/storeApiTypes';

@Component({
  selector: 'app-product-meta',
  templateUrl: './product-meta.component.html',
  styleUrl: './product-meta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductMetaComponent {
  shopUrl = environment.storefrontUrl;
  product = input.required<Schemas['Product']>();
}
