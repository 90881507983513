import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { ButtonPrimaryComponent } from '../button-primary/button-primary.component';
import { EventDirective } from '../../event.directive';
import { CartService } from '../../cart.service';
import { Schemas } from '../../../api-types/storeApiTypes';

@Component({
  selector: 'app-cart-button',
  imports: [ButtonPrimaryComponent, EventDirective],
  templateUrl: './cart-button.component.html',
  styleUrl: './cart-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartButtonComponent {
  location = input.required<string>();
  plant = input.required<Schemas['Product']>();
  quantity = input.required<number>();

  cssClass = input<string>('');

  readonly cart = inject(CartService);

  addToCart = async () => {
    const product = this.plant();
    const quantity = this.quantity();

    await this.cart.addToCart(product, quantity);
  };
}
