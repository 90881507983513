import { PropertyGroupIds } from '../../property.service';

export function getPropertyValue(
  groupId: string,
  name: string | undefined | null,
) {
  if (!name) {
    return name;
  }

  if (
    groupId === PropertyGroupIds['potsize'] ||
    groupId === PropertyGroupIds['height']
  ) {
    if (!name.endsWith('cm')) {
      return name + ' cm';
    }
  }
  return name;
}
