@if (product().ean) {
  <meta itemprop="gtin13" [content]="product().ean" />
  <meta itemprop="sku" [content]="product().ean" />
}

<meta
  itemprop="url"
  content="{{ shopUrl }}/{{ product().seoUrls![0].seoPathInfo }}"
/>

@if(product().media; as medias) {
  @if (medias[0]; as media) {
    <meta itemprop="image" [content]="media.media.url" />
  }
}
<meta itemprop="name" [content]="product().translated.name" />

<meta itemprop="itemCondition" content="https://schema.org/NewCondition" />

@if (product().translated.keywords) {
  <meta itemprop="keywords" [content]="product().translated.keywords" />
}

<meta
  itemprop="description"
  [content]="
    product().translated.metaDescription ||
    product().translated.description ||
    product().description ||
    ''
  "
/>

<div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
  @if(product().stock > 0) {
    <meta itemprop="availability" content="https://schema.org/InStock" />
  } @else {
    <meta itemprop="availability" content="https://schema.org/OutOfStock" />
  }
  <div
    itemprop="priceSpecification"
    itemtype="https://schema.org/PriceSpecification"
    itemscope
  >
    <meta itemprop="priceCurrency" content="EUR" />
    <meta
      itemprop="price"
      content="{{ product().calculatedPrice.totalPrice }}"
    />
    @if (product().calculatedCheapestPrice; as cheapestPrice) {
      <meta itemprop="minPrice" content="{{ cheapestPrice.unitPrice }}" />
    }
  </div>

  <div
    itemprop="shippingDetails"
    itemtype="https://schema.org/OfferShippingDetails"
    itemscope
  >
    <div
      itemprop="shippingRate"
      itemtype="https://schema.org/MonetaryAmount"
      itemscope
    >
      @if (product().calculatedPrice.totalPrice >= 60) {
        <meta itemprop="value" content="0" />
      } @else {
        <meta
          itemprop="value"
          [content]="product().calculatedPrice.totalPrice"
        />
      }
      <meta itemprop="currency" content="EUR" />
    </div>
    <div
      itemprop="shippingDestination"
      itemtype="https://schema.org/DefinedRegion"
      itemscope
    >
      <meta itemprop="addressCountry" content="DE" />
    </div>
    <div
      itemprop="deliveryTime"
      itemtype="https://schema.org/ShippingDeliveryTime"
      itemscope
    >
      <div
        itemprop="handlingTime"
        itemtype="https://schema.org/QuantitativeValue"
        itemscope
      >
        <meta itemprop="minValue" content="1" />
        <meta itemprop="maxValue" content="3" />
        <meta itemprop="unitCode" content="DAY" />
      </div>
      <div
        itemprop="transitTime"
        itemtype="https://schema.org/QuantitativeValue"
        itemscope
      >
        <meta itemprop="minValue" content="1" />
        <meta itemprop="maxValue" content="3" />
        <meta itemprop="unitCode" content="DAY" />
      </div>
    </div>
  </div>
</div>
