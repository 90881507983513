<app-button-primary
  [color]="'green'"
  [disabled]="plant().stock <= 0"
  [cssClass]="cssClass()"
  class="@container flex-grow min-w-0"
  [appEvent]="'add-to-cart'"
  [appEventData]="{
    product: plant().name,
    id: plant().id,
  }"
  [action]="addToCart"
>
  @if (plant().stock <= 0) {
    Ausverkauft
  } @else {
    <span class="@[180px]:inline-block hidden">In den</span> Warenkorb
  }
</app-button-primary>
